/* eslint-disable react/no-unknown-property, spaced-comment */
/* eslint-disable no-unused-vars,  no-undef, max-len, no-return-assign, jsx-a11y/anchor-is-valid, prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Text,
  Card,
  Icon,
  ContactCard,
  StampCard,
  // Badge,
  Form,
} from 'tabler-react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import ReactPlayer from 'react-player/youtube';
import {
  Pill,
  Collapse,
  TextLink,
  DisplayText,
  Heading,
  SectionHeading,
  Subheading,
  ButtonGroup,
  ToggleButton,
  Stack,
  Badge,
  Menu,
  Switch,
  Tooltip,
  IconButton,
} from '@contentful/f36-components';
import {
  MenuIcon,
  CheckCircleIcon,
  EditIcon,
  ThumbUpTrimmedIcon,
  MoreHorizontalIcon,
  MoreVerticalIcon,
} from '@contentful/f36-icons';
import moment from 'moment';
import intercomManager from '../../../helpers/intercomManager';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import providersActions from '../../../redux/reducers/providers';
import DenseTable from './components/DenseTable';
import formActions from '../../../redux/reducers/formDialog';
import VideoDialog from './components/VideoDialog';
import getYoutubeIdFromUrl from '../../../helpers/getYoutubeIdFromUrl';
import WashnImage from '../../../helpers/WashnImage';
import styles from '../../../styles/authenticated/providers.module.css';
import PricingPage from '../../PricingPage';

function ProvidersProfile({ match: { params } }) {
  const [redirect, setRedirect] = useState({ redirect: false, to: {} });
  const { uuid } = params;
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.providers.selectedProvider);
  const { role } = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [washnFeePercentage, setWashnFeePercentage] = useState();
  const [availableInMobileStore, setMobileStoreAvailability] = useState();
  const stripeSubscriptions = useSelector((state) => state.providers.stripeSubscriptions);
  const { addons, services, workers, memberships } = provider;
  const portalLink = stripeSubscriptions?.find((obj) => obj.plan_name === 'portal_link');
  const [isActive, setIsActive] = useState();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const reports = useSelector((state) => state.providers.userReports);

  // console.log('Reports', reports);

  useEffect(() => {
    dispatch(providersActions.getReports());
    const data = reports.map((item) => ({
      id: item.id,
      user_id: item.number,
    }));
  }, [reports]);
  // console.log('LOOK', reports);

  // console.log('LINKSSSS', provider.location);

  // console.log('SUBS...', stripeSubscriptions);

  // console.log('ADDON', addons);

  const handleVisibleChange = (e) => {
    dispatch(providersActions.updateMembershipVisibility(e.target.id, e.target.checked));
    const membership = memberships.find((m) => m.id === e.target.id);
    membership.visible = e.target.checked;
  };

  const [switchState, setSwitchState] = useState();

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#1D4C6F',
      '&$checked': {
        color: '#1D4C6F',
      },
      '&$checked + $track': {
        backgroundColor: '#1D4C6F',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    dispatch(providersActions.getProviderByPk(uuid));
  }, [uuid]);
  // console.log('ADMIN', provider);

  useEffect(() => {
    setMobileStoreAvailability(provider.mobile_store);
    setWashnFeePercentage(provider.washn_fee_percentage);
  }, [provider]);

  useEffect(() => {
    // get the stripe links
    dispatch(providersActions.getSubscriptions());
  }, []);

  // console.log('SUBS...', getSubscriptions);

  const handleMobileStoreSwitchChange = () => {
    setMobileStoreAvailability((prev) => !prev);
    dispatch(
      providersActions.updateProviderMobileStoreAvailabilityByPk({
        id: provider.id,
        mobileStore: !availableInMobileStore,
        userRole: role,
      }),
    );
  };

  const handleWashnFeePercentageChange = () => {
    dispatch(
      providersActions.updateProviderByPk({
        id: provider.id,
        washn_fee_percentage: washnFeePercentage,
        userRole: role,
      }),
    );
  };

  if (!uuid || !provider.name) return null;

  document.title = `${provider.name} - Washn Admin`;

  const handleDelete = (id, type, providerId) => {
    if (type === 'service') dispatch(providersActions.deleteServiceByPk({ id, providerId }));
    if (type === 'worker') dispatch(providersActions.disableWorker(id, providerId));
    else dispatch(providersActions.deleteAddonByPk({ id, providerId }));
  };

  const handleProviderVerification = (id, verified) => {
    dispatch(
      providersActions.updateProviderByPk({
        verified: !verified,
        id,
        userRole: role,
      }),
    );
  };

  // Verify Location Button
  const generateVerifiedBadge = () => {
    if (role === 'admin') {
      return (
        <IconButton
          size="small"
          // md={3}
          variant={provider.verified ? 'positive' : 'negative'}
          icon={<CheckCircleIcon />}
          // icon={provider.verified ? 'stop-circle' : 'play-circle'}
          onClick={() =>
            dispatch(
              formActions.displayForm({
                title: 'Confirmation',
                description: provider.verified
                  ? `Are you sure you want to disable provider location "${provider.name}"?.`
                  : `Are you sure you want to enable provider location "${provider.name}"?.`,
                handleSubmit: () => handleProviderVerification(uuid, provider.verified),
                closeButtonText: 'Cancel',
                submitButtonText: 'Confirm',
              }),
            )
          }
          footer={
            <Badge variant={provider.verified ? 'primary' : 'negative'} className="mr-1">
              {provider.verified ? 'Account Enabled' : 'Account Disabled'}
            </Badge>
          }
        />
      );
    }
    return null;
  };

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleSubmit = (value) => {
    dispatch(
      providersActions.updateProviderByPk({
        video: getYoutubeIdFromUrl(value),
        id: uuid,
        userRole: role,
      }),
    );
    setOpen(!open);
  };

  const visibleMemberships =
    role === 'admin' ? memberships : memberships.filter((m) => m.visible === true);
  // console.log('MEMBERSHIPS', visibleMemberships);
  const membershipsColumns = [
    'name',
    'description',
    'price',
    'platform fee',
    'active customers',
    'product-id',
    'created at',
  ];
  if (role === 'admin') {
    membershipsColumns.push('toggle visibility');
  }
  // console.log('MEMBERSHIPS');
  // console.log('LIMIT', provider);

  return (
    <SiteWrapper>
      {redirect.redirect && <Redirect push to={redirect.to} />}
      <VideoDialog open={open} handleClose={handleDialog} handleSubmit={handleSubmit} />
      <div className="my-3 my-md-5">
        <Container>
          <Container>
            <Grid.Row cards deck>
              <Grid.Col md={4}>
                <Card>
                  {/* Location Section */}
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">{provider.name} Location</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Stack flexDirection="row" spacing="spacingS">
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="The location info contains all the relative information."
                        >
                          <Icon prefix="fe" name="info" />
                        </Tooltip>
                        <Button
                          type="button"
                          icon="edit"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: `/providers/update/${uuid}`,
                              },
                            })
                          }
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <Stack>
                      <Text fontSize="fontSizeL" marginTop="spacingS" lineHeight="lineHeightL">
                        Location ID: {provider.location_id} Site#: {provider.sitenum}
                      </Text>
                      <Text fontSize="fontSizeL" marginTop="spacingS" lineHeight="lineHeightL">
                        Location {provider.location.coordinates[0]}{' '}
                        {provider.location.coordinates[1]}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeL" marginTop="spacingS" lineHeight="lineHeightL">
                        <Icon prefix="fe" name="map" />
                        Address
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginTop="none">
                        {provider.address}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginTop="spacingXs">
                        {' '}
                        {provider.city}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="spacingXs">
                        {provider.state}, {provider.zip}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeL" marginBottom="spacingXs">
                        Contact Info
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="none">
                        <Icon prefix="fe" name="mail" /> {provider.email}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="none">
                        <Icon prefix="fe" name="phone" /> {provider.phone}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="spacingXs">
                        <Icon prefix="fe" name="globe" /> {provider.website}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeL" marginBottom="spacingXs">
                        Business Decision Maker
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="spacingXs">
                        <Icon prefix="fe" name="user" color="red" /> {provider.decision_maker_name}{' '}
                        {provider.decision_maker_lastname}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeL" marginBottom="spacingXs">
                        Business Point Of Contact
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginBottom="spacingXs">
                        <Icon prefix="fe" name="user" /> {provider.point_of_contact_name}{' '}
                        {provider.point_of_contact_lastname}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginTop="spacingS" marginBottom="spacingS">
                        Business Type: {provider.business_type}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="fontSizeM" marginTop="spacingS">
                        Service Flow: {provider.washn_fee_percentage}
                      </Text>
                    </Stack>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  {/* Logo Section */}
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Business Logo</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Tooltip
                        placement="top"
                        id="tooltip-1"
                        content="This is your business logo and is used the Mobile App."
                      >
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Card.Options>
                  </Card.Header>
                  <div
                    style={{
                      maxHeight: '100%',
                      minHeight: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: 10,
                    }}
                  >
                    <WashnImage
                      style={{ maxHeight: '350px', width: 'auto' }}
                      src={provider.image}
                      alt="Provider Image"
                      skeletonHeigth={250}
                    />
                  </div>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  {/* Location Section */}
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Youtube Video</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Tooltip
                        placement="top"
                        id="tooltip-1"
                        content="Only admin can change this component. Contact support if you want change this component"
                      >
                        <Icon disabled prefix="fe" name="info" />
                      </Tooltip>
                      {role === 'admin' && (
                        <Button
                          type="button"
                          icon="video"
                          color="primary"
                          outline
                          onClick={handleDialog}
                        >
                          {provider.video === null ? 'Add Video' : 'Change Video'}
                        </Button>
                      )}
                    </Card.Options>
                  </Card.Header>
                  <ReactPlayer
                    style={{
                      maxWidth: '90%',
                      maxHeight: '100%',
                      paddingTop: 14.5,
                      paddingBottom: 10,
                      alignSelf: 'center',
                    }}
                    url={`https://www.youtube.com/watch?v=${provider.video}`}
                  />
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
          <Container>
            <Grid.Row cards deck>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Stripe Connect</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Stack flexDirection="row" spacing="spacingS">
                        <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                          <Icon prefix="fe" name="info" />
                        </Tooltip>
                        {role === 'admin' && (
                          <Button
                            type="button"
                            color={provider.stripe_verified ? 'success' : 'danger'}
                            // color="primary"
                            icon="play-circle"
                            outline
                            onClick={() => dispatch(providersActions.openStripeLink(uuid))}
                          >
                            Admin Setup Stripe
                          </Button>
                        )}
                        {!provider.stripe_account && role === 'provider' && (
                          <Button
                            type="button"
                            color="primary"
                            icon="play-circle"
                            outline
                            onClick={() => dispatch(providersActions.openStripeLink(uuid))}
                          >
                            Setup Stripe
                          </Button>
                        )}
                        {// provider.stripe_account &&
                          !provider.stripe_verified &&
                          role === 'provider' && (
                            <Button
                              rel="noreferrer"
                              type="button"
                              icon="alert-circle"
                              color="danger"
                              // outline
                              state="active"
                            >
                              <a
                                target="_blank"
                                style={{ color: '#EEEEEE' }}
                                href="https://dashboard.stripe.com/"
                                rel="noreferrer"
                              >
                                Fix Stripe Connect
                              </a>
                            </Button>
                          )}
                        {provider.stripe_account &&
                          provider.stripe_verified &&
                          role === 'provider' && (
                            <a
                              target="_blank"
                              style={{ color: '#EEEEEE' }}
                              href="https://dashboard.stripe.com/"
                              rel="noreferrer"
                            >
                              <Button type="button" color="primary" outline icon="external-link">
                                Stripe
                              </Button>
                            </a>
                          )}
                      </Stack>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer flexWrap="wrap" className="text-left">
                    <Badge variant={provider.verified ? 'positive' : 'negative'}>
                      {provider.stripe_account
                        ? 'Stripe Account Linked'
                        : 'Stripe Account Not Linked'}
                    </Badge>
                  </Card.Footer>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Stripe Verification Status</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer flexWrap="wrap" className="text-left">
                    <Badge variant={provider.verified ? 'positive' : 'warning'}>
                      {provider.stripe_verified
                        ? 'Stripe Verification Completed'
                        : 'Stripe Verification Pending'}
                    </Badge>
                  </Card.Footer>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">
                        {generateVerifiedBadge()} Washn Account Status
                      </Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer className="text-left" flexWrap="wrap">
                    <ButtonGroup variant="spaced" spacing="spacingM">
                      <Badge variant={provider.verified ? 'positive' : 'negative'}>
                        {provider.verified ? 'Account Enabled' : 'Account Disabled'}
                      </Badge>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Mobile App Status</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Stack flexDirection="row" spacing="spacingS" mar>
                        <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                          <Icon prefix="fe" name="info" />
                        </Tooltip>
                        {role === 'admin' && (
                          <Switch
                            isChecked={availableInMobileStore}
                            onChange={handleMobileStoreSwitchChange}
                            defaultChecked={false}
                          />
                        )}
                        {provider.verified && role === 'provider' && (
                          <Switch
                            isChecked={availableInMobileStore}
                            onChange={handleMobileStoreSwitchChange}
                            defaultChecked={false}
                            // isDisabled
                          />
                        )}
                        {!provider.verified && role === 'provider' && (
                          <Switch
                            isChecked={availableInMobileStore}
                            onChange={handleMobileStoreSwitchChange}
                            defaultChecked={false}
                            isDisabled
                          />
                        )}
                      </Stack>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer>
                    <Badge
                      variant={provider.mobile_store ? 'primary-filled' : 'negative'}
                      className="mr-1"
                    >
                      {provider.mobile_store ? 'Mobile Store Active' : 'Maintenance Mode'}
                    </Badge>
                  </Card.Footer>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Stripe Account Information</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer>
                    <ButtonGroup variant="spaced" spacing="spacingM">
                      <Badge variant={provider.stripe_account ? 'primary-filled' : 'negative'}>
                        {provider.stripe_account ? provider.stripe_account : 'No Stripe Account #'}
                      </Badge>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Grid.Col>
              <Grid.Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Billing</Subheading>
                    </Card.Title>
                    <Card.Options>
                      <Stack flexDirection="row" spacing="spacingS">
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="View and manage your billing account."
                        >
                          <Icon prefix="fe" name="info" />
                        </Tooltip>
                        <div>
                          <a
                            target="_blank"
                            // href="https://billing.stripe.com/p/login/test_28oeVybgD8O30XS288" // Staging
                            href="https://billing.stripe.com/p/login/8wMbLt8QL2SqbjG3cc" // Prod
                            // href={stripeSubscriptions.link}
                            rel="noreferrer"
                          >
                            <Button type="button" icon="credit-card" color="primary" outline>
                              Billing Portal
                            </Button>
                          </a>
                        </div>
                      </Stack>
                    </Card.Options>
                  </Card.Header>
                  <Card.Footer>
                    <ButtonGroup variant="spaced" spacing="spacingM">
                      <Badge variant="primary-filled">{provider.stripe_cust_id}</Badge>
                      <Badge variant="primary-filled">{provider.stripe_cust_status}</Badge>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Subheading marginBottom="none">Pricing Table</Subheading>
                </Card.Title>
                <Card.Options>
                  <Stack flexDirection="row" spacing="spacingS">
                    <Tooltip placement="top" id="tooltip-1" content="Expand to subscribe to Washn.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                    <Button
                      icon="maximize-2"
                      type="button"
                      color="primary"
                      outline
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      Subscribe to Washn
                    </Button>
                  </Stack>
                </Card.Options>
              </Card.Header>
              <Collapse isExpanded={isExpanded}>
                <PricingPage />
              </Collapse>
            </Card>
          </Container>
          <Container>
            <Grid.Row>
              <Grid.Col md={12}>
                {/* WORKERS */}
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Team Members {workers.length}</Subheading>
                    </Card.Title>
                    <Card.Options>
                      {role === 'admin' && (
                        <Button
                          type="button"
                          icon="user-plus"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: '/providers/workers/create/',
                                state: { create: true, provider },
                              },
                            })
                          }
                        >
                          Admin Add A Team Member
                        </Button>
                      )}
                      {provider.verified && role === 'provider' && (
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip placement="top" id="tooltip-1" content="Add your team members">
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            type="button"
                            icon="user-plus"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/workers/create/',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add
                          </Button>
                        </Stack>
                      )}
                      {!provider.verified && role === 'provider' && (
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip
                            placement="top"
                            id="tooltip-1"
                            content="The location is disabled. Check out the Help Center for more information"
                          >
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            disabled
                            type="button"
                            icon="user-plus"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/workers/create/',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add
                          </Button>
                        </Stack>
                      )}
                    </Card.Options>
                  </Card.Header>
                  <DenseTable
                    data={
                      workers.length === 0
                        ? []
                        : workers
                            .filter((worker) => worker.user.role === 'worker' || 'provider')
                            .map(({ user }) => ({
                              key: user.id,
                              item: [
                                {
                                  content: <Text>{user.email}</Text>,
                                },
                                {
                                  content: <Text>{user.role}</Text>,
                                },
                                {
                                  content:
                                    role !== 'worker' ? (
                                      <Button
                                        size="sm"
                                        color="red"
                                        onClick={() =>
                                          dispatch(
                                            formActions.displayForm({
                                              title: 'Confirm delete',
                                              description: `Are you sure you want to delete the worker:${user.email}?`,
                                              handleSubmit: () =>
                                                handleDelete(user.email, 'worker', provider.id),
                                              closeButtonText: 'Cancel',
                                              submitButtonText: 'Confirm',
                                            }),
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                    ) : null,
                                },
                              ],
                            }))
                    }
                    columns={[
                      'email',
                      'role',
                      role !== 'worker' ? 'manage' : null,
                      'firstname',
                      'lastname',
                      'phone',
                    ]}
                  />
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
          <Container>
            <Grid.Row>
              <Grid.Col md={7}>
                {/* SERVICES */}
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Services  {services.length}/{/**{provider.service_limit} */}</Subheading>
                    </Card.Title>
                    <Card.Options>
                      {role === 'admin' && (
                        <Button
                          type="button"
                          icon="tag"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: '/providers/services/create',
                                state: { create: true, provider },
                              },
                            })
                          }
                        >
                          Admin Add A Service
                        </Button>
                      )}
                      {provider.verified && role === 'provider' && (
                        <Button
                          type="button"
                          icon="tag"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: '/providers/services/create',
                                state: { create: true, provider },
                              },
                            })
                          }
                        >
                          Add A Service
                        </Button>
                      )}
                      {/**
                      {provider.verified && role === 'provider' && (
                        if (provider.service_limit > provider.services.length
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip placement="top" id="tooltip-1" content="Add a few Services.">
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            type="button"
                            icon="tag"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/services/create',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add A Service
                          </Button>
                        </Stack>
                      ))}
                      {!provider.verified && role === 'provider' && (
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip
                            placement="top"
                            id="tooltip-1"
                            content="The location is disabled. Check out the Help Center for more information"
                          >
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            disabled
                            type="button"
                            icon="tag"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/services/create',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add A Service
                          </Button>
                        </Stack>
                      )}
                      {provider.service_limit > provider.services.length && (
                          <Stack flexDirection="row" spacing="spacingS" mar>
                            <Tooltip
                              placement="top"
                              id="tooltip-1"
                              content="The location is disabled. Check out the Help Center for more information"
                            >
                              <Icon prefix="fe" name="info" />
                            </Tooltip>
                            <Button
                              // disabled
                              type="button"
                              icon="tag"
                              color="primary"
                              outline
                              onClick={() =>
                                setRedirect({
                                  redirect: true,
                                  to: {
                                    pathname: '/providers/services/create',
                                    state: { create: true, provider },
                                  },
                                })
                              }
                            >
                              Limit Button Service
                            </Button>
                          </Stack>
                        )} */}
                    </Card.Options>
                  </Card.Header>
                  <DenseTable
                    data={
                      services.length === 0
                        ? []
                        : services
                            .sort((a) => (a.deal_expiry ? -1 : 1))
                            .map((service) => {
                              const today = moment().utc().local();
                              const dealExpiration = moment.utc(service?.deal_expiry);
                              const localDate = moment(dealExpiration).local();
                              const toRender = localDate.format('MMM Do YY h:mm A');
                              const serviceCreated = moment.utc(service?.created_at);
                              const servicelocalDate = moment(serviceCreated).local();
                              const servicetoRender = servicelocalDate.format('MMM Do YY h:mm A');
                              const serviceModified = moment.utc(service?.modified_at);
                              const serviceModifiedlocalDate = moment(serviceModified).local();
                              const serviceModifytoRender =
                                serviceModifiedlocalDate.format('MMM Do YY h:mm A');
                              const serviceDeleted = moment.utc(service?.deleted_at);
                              const serviceDeleteDate = moment(serviceDeleted).local();
                              const serviceDeletedRender =
                                serviceDeleteDate.format('MMM Do YY h:mm A');
                              // console.log('Services', services);
                              return {
                                key: service.id,
                                item: [
                                  {
                                    content: (
                                      <Menu>
                                        <Menu.Trigger>
                                          <TextLink as="button" variant="primary">
                                            {service.name}
                                          </TextLink>
                                        </Menu.Trigger>
                                        <Menu.List>
                                          <Menu.SectionTitle>Options</Menu.SectionTitle>
                                          <Menu.Item
                                            type="button"
                                            onClick={() =>
                                              setRedirect({
                                                redirect: true,
                                                to: {
                                                  pathname: `/providers/services/update/${service.id}`,
                                                  state: { create: false, provider },
                                                },
                                              })
                                            }
                                          >
                                            Edit
                                          </Menu.Item>
                                          <Menu.Item
                                            type="button"
                                            onClick={() =>
                                              dispatch(
                                                formActions.displayForm({
                                                  title: 'Confirm Bedore You Delete',
                                                  description: `Are you sure you want to delete the ${service.name} service?.`,
                                                  handleSubmit: () =>
                                                    handleDelete(
                                                      service.id,
                                                      'service',
                                                      provider.id,
                                                    ),
                                                  closeButtonText: 'Cancel',
                                                  submitButtonText: 'Confirm',
                                                }),
                                              )
                                            }
                                          >
                                            Delete
                                          </Menu.Item>
                                        </Menu.List>
                                      </Menu>
                                    ),
                                  },
                                  {
                                    content: (
                                      <Text RootComponent="span">${service.price.toFixed(2)}</Text>
                                    ),
                                  },
                                  {
                                    content: <Text RootComponent="span">{service.deal_price}</Text>,
                                  },
                                  {
                                    content: (
                                      <Text RootComponent="span">
                                        {service.deal_price
                                          ? `${Math.round(
                                              ((service.price - service.deal_price) /
                                                service.price) *
                                                100,
                                            )}%`
                                          : ''}
                                      </Text>
                                    ),
                                  },
                                  {
                                    content: (
                                      <Text
                                        style={{
                                          color:
                                            localDate.diff(today, 'days') > 0 ? 'green' : 'red',
                                        }}
                                        RootComponent="span"
                                      >
                                        {toRender === 'Invalid date' ? '' : toRender}
                                      </Text>
                                    ),
                                  },
                                  {
                                    content:
                                      role !== 'worker' ? (
                                        <>
                                          <Menu>
                                            <Menu.Trigger>
                                              <IconButton
                                                variant="transparent"
                                                icon={<MoreHorizontalIcon variant="primary" />}
                                              />
                                            </Menu.Trigger>
                                            <Menu.List>
                                              <Menu.Item type="button">
                                                Created:{' '}
                                                {servicetoRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(service.created_at).valueOf(),
                                                    ).format('MMM Do YY hh:mm A')}
                                              </Menu.Item>
                                              <Menu.Item>
                                                Changed:{' '}
                                                {serviceModifytoRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(service.modified_at).valueOf(),
                                                    ).format('MMM Do YY hh:mm A')}
                                              </Menu.Item>

                                              <Menu.Item>
                                                Deleted:{' '}
                                                {serviceDeletedRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(service.deleted_at).valueOf(),
                                                    ).format('MMM Do YY hh:mm A')}
                                              </Menu.Item>
                                            </Menu.List>
                                          </Menu>
                                        </>
                                      ) : null,
                                  },
                                ],
                              };
                            })
                    }
                    columns={[
                      'Name',
                      'Price',
                      'Deal Price',
                      '%',
                      'Deal End',
                      role !== 'worker' ? 'Activity' : null,
                    ]}
                  />
                </Card>
              </Grid.Col>
              <Grid.Col md={5}>
                {/* ADD-ONS */}
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Add-Ons {addons.length} </Subheading>
                    </Card.Title>
                    <Card.Options>
                      {role === 'admin' && (
                        <Button
                          type="button"
                          icon="file-plus"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: '/providers/addons/create',
                                state: { create: true, provider },
                              },
                            })
                          }
                        >
                          Admin Add an Add-On
                        </Button>
                      )}
                     {/** {provider.verified && role === 'provider' && provider.service_limit < services.length &&  (  */}
                      {provider.verified && role === 'provider' && (
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip placement="top" id="tooltip-1" content="Add a few Add-ons">
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            type="button"
                            icon="file-plus"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/addons/create',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add an Add-On
                          </Button>
                        </Stack>
                      )}
                      {!provider.verified && role === 'provider' && (
                        <Stack flexDirection="row" spacing="spacingS" mar>
                          <Tooltip
                            placement="top"
                            id="tooltip-1"
                            content="The location is disabled. Check out the Help Center for more information"
                          >
                            <Icon prefix="fe" name="info" />
                          </Tooltip>
                          <Button
                            disabled
                            type="button"
                            icon="file-plus"
                            color="primary"
                            outline
                            onClick={() =>
                              setRedirect({
                                redirect: true,
                                to: {
                                  pathname: '/providers/addons/create',
                                  state: { create: true, provider },
                                },
                              })
                            }
                          >
                            Add an Add-On
                          </Button>
                        </Stack>
                      )}
                    </Card.Options>
                  </Card.Header>
                  <DenseTable
                    data={
                      addons.length === 0
                        ? []
                        : addons
                            .sort((a) => (a.created_at ? 1 : -1))
                            .map((addon) => {
                              const addonCreated = moment.utc(addon?.created_at);
                              const addonCreatedlocalDate = moment(addonCreated).local();
                              const addonCreatedtoRender =
                                addonCreatedlocalDate.format('MMM Do YY h:mm A');
                              const addonModified = moment.utc(addon?.modified_at);
                              const addonModifiedlocalDate = moment(addonModified).local();
                              const addonModifiedtoRender =
                                addonModifiedlocalDate.format('MMM Do YY h:mm A');
                              const addonDeleted = moment.utc(addon?.deleted_at);
                              const addonDeletedlocalDate = moment(addonDeleted).local();
                              const addonDeletedtoRender =
                                addonDeletedlocalDate.format('MMM Do YY h:mm A');
                              return {
                                key: addon.id,
                                item: [
                                  {
                                    content: (
                                      <Menu>
                                        <Menu.Trigger>
                                          <button type="button">
                                            <TextLink as="button" variant="primary">
                                              {addon.name}
                                            </TextLink>{' '}
                                          </button>
                                        </Menu.Trigger>
                                        <Menu.List>
                                          <Menu.SectionTitle>Options</Menu.SectionTitle>
                                          <Menu.Item
                                            type="button"
                                            onClick={() =>
                                              setRedirect({
                                                redirect: true,
                                                to: {
                                                  pathname: `/providers/addons/update/${addon.id}`,
                                                  state: { create: false, provider },
                                                },
                                              })
                                            }
                                          >
                                            Edit
                                          </Menu.Item>
                                          <Menu.Item
                                            type="button"
                                            onClick={() =>
                                              dispatch(
                                                formActions.displayForm({
                                                  title: 'Confirm Before Your Delete',
                                                  description: `Are you sure you want to delete the ${addon.name} addon?.`,
                                                  handleSubmit: () =>
                                                    handleDelete(addon.id, 'addon', provider.id),
                                                  closeButtonText: 'Cancel',
                                                  submitButtonText: 'Confirm',
                                                }),
                                              )
                                            }
                                          >
                                            Delete
                                          </Menu.Item>
                                        </Menu.List>
                                      </Menu>
                                    ),
                                  },
                                  {
                                    content: (
                                      <Text RootComponent="span">${addon.price.toFixed(2)}</Text>
                                    ),
                                  },
                                  {
                                    content:
                                      role !== 'worker' ? (
                                        <>
                                          <Menu>
                                            <Menu.Trigger>
                                              <IconButton
                                                variant="transparent"
                                                icon={<MoreHorizontalIcon variant="primary" />}
                                                aria-label="toggle menu"
                                              />
                                            </Menu.Trigger>
                                            <Menu.List>
                                              <Menu.Item type="button">
                                                Created:{' '}
                                                {addonCreatedtoRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(addon.created_at).valueOf(),
                                                    ).format('MMM Do YY hh:mm A')}
                                              </Menu.Item>
                                              <Menu.Item>
                                                Changed:{' '}
                                                {addonModifiedtoRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(addon.modified_at).valueOf(),
                                                    ).format(' MMM Do YY hh:mm A')}
                                              </Menu.Item>
                                              <Menu.Item>
                                                Deleted:{' '}
                                                {addonDeletedtoRender === 'Invalid date'
                                                  ? ''
                                                  : moment(
                                                      new Date(addon.deleted_at).valueOf(),
                                                    ).format(' MMM Do YY hh:mm A')}
                                              </Menu.Item>
                                            </Menu.List>
                                          </Menu>
                                        </>
                                      ) : null,
                                  },
                                ],
                              };
                            })
                    }
                    columns={['name', 'price', role !== 'worker' ? 'Activity' : null]}
                  />
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
          <Container>
            <Grid.Row>
              <Grid.Col>
                {/* MEMBERSHIPS */}
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <Subheading marginBottom="none">Memberships {memberships.length}</Subheading>
                    </Card.Title>
                    <Card.Options>
                      {role === 'admin' && (
                        <Button
                          type="button"
                          icon="award"
                          color="primary"
                          outline
                          onClick={() =>
                            setRedirect({
                              redirect: true,
                              to: {
                                pathname: '/providers/memberships/create/',
                                state: { create: true, provider },
                              },
                            })
                          }
                        >
                          Add A Membership
                        </Button>
                      )}
                    </Card.Options>
                  </Card.Header>
                  {!!visibleMemberships.length && (
                    <DenseTable
                      data={
                        visibleMemberships.length === 0
                          ? []
                          : visibleMemberships?.map((membership) => {
                              const item = [
                                {
                                  content: <Text RootComponent="span">{membership.name}</Text>,
                                },
                                {
                                  content: (
                                    <Text RootComponent="span">{membership.description}</Text>
                                  ),
                                },
                                {
                                  content: (
                                    <Text RootComponent="span">
                                      ${(membership.price / 100).toFixed(2)}
                                    </Text>
                                  ),
                                },
                                {
                                  content: (
                                    <Text RootComponent="span">
                                      {membership.washn_fee_percentage.toFixed(2)}%
                                    </Text>
                                  ),
                                },
                                {
                                  content: (
                                    <Text RootComponent="span">
                                      {membership.order_details.length}
                                    </Text>
                                  ),
                                  className: styles.centered,
                                },
                                {
                                  content: (
                                    <Badge variant="primary-filled">
                                      <Text RootComponent="span">
                                        {membership.stripe_product_id}
                                      </Text>
                                    </Badge>
                                  ),
                                },
                                {
                                  content: moment(
                                    new Date(membership.creation_date).valueOf(),
                                  ).format('MMM Do YY hh:mm A'),
                                },
                              ];
                              if (role === 'admin') {
                                item.push({
                                  content: (
                                    <CustomSwitch
                                      defaultChecked={membership.visible}
                                      onChange={(e) => handleVisibleChange(e)}
                                      id={membership.id}
                                    />
                                  ),
                                });
                              }
                              return {
                                key: membership.id,
                                item,
                              };
                            })
                      }
                      columns={membershipsColumns}
                    />
                  )}
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersProfile);
